<template>
  <el-main>
    <div class="blodTitle">华为服务号</div>
    <div class="bannerImgBox">
      <img :src="imgUrlHead + 'banner.png'" alt="" />
      <div class="onceOpen" v-if="!isBuy" @click="tuBuy">立即开通</div>
    </div>
    <div class="blodTitle">功能介绍</div>
    <div class="functionArr">
      <div class="functionItem" v-for="(item, index) in functionArr" :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="xian"></div>
        <div class="desc">{{ item.desc }}</div>
      </div>
    </div>
    <div class="blodTitle">服务优势</div>
    <div class="serAdvantage">
      <div class="serAdvantageItem" v-for="(item, index) in serAdvantage" :key="index">
        <div>
          <img :src="imgUrlHead + item.img" alt="" />
        </div>
        <div class="right">
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div class="blodTitle">场景介绍</div>
    <div class="scene">
      <div class="sceneItem" v-for="(item, index) in scene" :key="index">
        <div class="top">
          <img :src="imgUrlHead + item.img" alt="" />
        </div>
        <div class="bot">{{ item.desc }}</div>
      </div>
    </div>
    <div class="blodTitle">优秀案例</div>
    <div class="anliList">
      <div v-for="(item, index) in moreArr" :key="index">
        <img class="qr" :src="imgUrlHead + item.qrcode" alt="" />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <BuyDialog :buyBox="buyBox" @closeBuy="closeBuy" type="huawei"></BuyDialog>
  </el-main>
</template>

<script>
import { imgUrlHead } from '@/util/config'
import BuyDialog from './components/buyDialog.vue'
export default {
  components: {
    BuyDialog
  },
  data() {
    return {
      functionArr: [
        { title: '服务聚合与分发', desc: '华为服务号为商家提供用户统一服务平台，支持移动端站点、H5及快应用等多种类型的服务分发，实现用户多服务沉淀。' },
        { title: '用户互动连接', desc: '华为服务号为商家开放服务消息推送功能，并开放消息上下行，形成商家与用户的即时互动，打造商家与用户的互动生态。' },
        { title: '多样化营销工具', desc: '华为服务号为商家开放多样化的营销工具，如裂变营销、优惠促销等功能扩展，助力商家的营销转化落地。' }
      ],
      serAdvantage: [
        { img: 'icon01.png', title: '降低接入成本', desc: '多样化商家服务展示模块，一次接入，多端分发，降低商家接入成本。' },
        { img: 'icon02.png', title: '增强用户粘性', desc: '消息互动能力将用户与商家形成连接，帮助商家为用户及时回复，提升用户体验。' },
        { img: 'icon03.png', title: '实现用户沉淀', desc: '多样化营销工具为商家搭建营销阵地，帮助商家实现用户留存与复购。' },
        { img: 'icon04.png', title: '提升商业转化', desc: '多端服务分发能力，帮助商家主动高效触达用户，提升商业转化。' }
      ],
      scene: [
        { img: 'pic01.png', desc: '打开负一屏，呈现我的服务消息卡片，可通过点击卡片上的服务号直接进入服务号主页或点击服务号中心进入服务号聚合页。' },
        { img: 'pic02.png', desc: '打开生活服务APP首页，通过服务号中心或直接进入底部菜单服务号页面，可快速找到华为服务号并进入华为服务号主页。' },
        { img: 'pic03.png', desc: '打开服务号中心，呈现服务号类目和服务号推荐卡片，通过点击类目可进入服务号列表页，点击服务号内容进入服务号主页。' },
        { img: 'pic04.png', desc: '打开全部历史消息列表，呈现服务号logo和服务号全部历史消息，通过点击服务号logo可直接进入服务号主页。' },
        { img: 'pic05.png', desc: '打开服务号主页，呈现服务号主页所有组件内容，通过点击对应组件分发商家服务，从而产生交易与转化。' }
      ],
      moreArr: [
        { name: '雅兰天丽美妆', qrcode: 'huaweiqr.png' },
        { name: '天宜世家家居', qrcode: 'huaweiqr.png' },
        { name: '伊乐贝斯女装', qrcode: 'huaweiqr.png' },
        { name: '日易惠百货用品', qrcode: 'huaweiqr.png' },
        { name: '新稻香农产品', qrcode: 'huaweiqr.png' }
      ],
      imgUrlHead: imgUrlHead + 'huawei/',
      buyBox: false,
      isBuy: 1
    }
  },
  methods: {
    getIsBuy() {
      this.$axios
        .post(this.$api.set.buyGorH.getIsPay, {
          type: 2
        })
        .then((res) => {
          if (res.code === 0) {
            this.isBuy = res.result.is_pay
          }
        })
    },
    tuBuy() {
      this.buyBox = true
    },
    closeBuy() {
      this.buyBox = false
      this.getIsBuy()
    }
  }
}
</script>

<style lang="less" scoped>
.onceOpen {
  color: #fff;
  font-size: 20px;
  border-radius: 4px;
  background: #0074ff;
  box-shadow: 0px 8px 16px 0px rgba(0, 106, 234, 0.21);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 10px 35px;
}
.el-main {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background-color: #fff;
  .blodTitle {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin: 40px 0 20px 0;
    .subTitle {
      color: #5654f3;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      margin-left: 23px;
    }
  }
  .bannerImgBox {
    position: relative;
    margin-bottom: 30px;
    .onceOpen {
      position: absolute;
      right: 75px;
      top: 50%;
      transform: translateY(-50%);
    }
    img {
      width: 100%;
    }
  }
  .functionArr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .functionItem:nth-child(2) {
      margin: 0 20px;
    }
    .functionItem {
      padding: 20px;
      flex: 1;
      background-color: #f7f8fa;
      height: 100%;
      .title {
        font-size: 16px;
        font-weight: 500;
      }
      .xian {
        width: 18px;
        height: 2px;
        background: #333333;
        border-radius: 1px;
        margin: 10px 0 16px 0;
      }
      .desc {
        font-size: 14px;
        color: #333333;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
  .serAdvantage {
    display: flex;
    flex-wrap: wrap;
    .serAdvantageItem:not(:last-child) {
      margin-right: 15px;
    }
    .serAdvantageItem {
      margin-bottom: 20px;
      width: 384px;
      flex-shrink: 0;
      background: #ffffff;
      border: 1px solid #e7e7e7;
      padding: 25px 20px 30px 30px;
      display: flex;
      align-items: center;
      .right {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        .title {
          font-size: 16px;
          margin-bottom: 20px;
        }
        .desc {
          line-height: 24px;
        }
      }
    }
  }
  .scene {
    display: flex;
    flex-wrap: wrap;
    .sceneItem {
      width: 305px;
      flex-shrink: 0;
      margin-bottom: 20px;
      .bot {
        line-height: 24px;
        margin-top: 10px;
      }
    }
    .sceneItem:not(:last-child) {
      margin-right: 20px;
    }
  }
  .anliList {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
    img {
      width: 110px;
      height: 110px;
    }
    & > div {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-bottom: 20px;
    }
    & > div:not(:last-child) {
      margin-right: 180px;
    }
  }
}
</style>
